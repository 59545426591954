
/*
    Sitewide Endeca configuration.
    Anything set here will be used as the default for all settings used by the endeca instances
    on the page. These can be overwritten in the instance configuration files for each
    endeca instance.

*/

jQuery(document).ready(function(){
    site.endeca.configuration = {
        query: {
            MDEXHost: 'localhost',
            MDEXPort: Drupal?.settings?.endeca?.ports ? Drupal?.settings?.endeca?.ports?.mdex : {
                'en_US': 26410,
                'en_CA': 26410,
                'fr_CA': 26415,
                'zh_CN':26435,
                'de_DE': 26445,
                'fr_FR': 26450,
                'de_AT': 26455,
                'he_IL': 26440,
                'en_AU': 26460,
                'es_MX': 26465,
                'ja_JP': 26470,
                'ko_KR': 26475,
                'pt_BR': 26480,
                'nl_NL': 26485,
                'fr_BE': 26490,
                'nl_BE': 26495,
                'zh_TW': 16401,
                'tr_TR': 16411,
                'it_IT': 16416,
                'no_NO': 16421,
                'ru_RU': 16426,
                'th_TH': 16431,
                'es_ES': 16436,
                'de_CH': 16441,
                'fr_CH': 16446
            },
            logHost: 'localhost',
            logPort: Drupal?.settings?.endeca?.ports ? Drupal?.settings?.endeca?.ports?.log : {
                'en_US': 26414,
                'en_CA': 26414,
                'fr_CA': 26419,
                'zh_CN':26439,
                'de_DE': 26449,
                'fr_FR': 26454,
                'de_AT': 26459,
                'he_IL': 26444,
                'en_AU': 26464,
                'es_MX': 26469,
                'ja_JP': 26474,
                'ko_KR': 26479,
                'pt_BR': 26484,
                'nl_NL': 26489,
                'fr_BE': 26494,
                'nl_BE': 26499,
                'zh_TW': 16402,
                'tr_TR': 16412,
                'it_IT': 16417,
                'no_NO': 16422,
                'ru_RU': 16427,
                'th_TH': 16432,
                'es_ES': 16437,
                'de_CH': 16442,
                'fr_CH': 16447
            },

            defaultDimensionIds: [8061,8062,8127,8050,8063,8053,8089,8051,8095,8096,8147,8052,8054,8157],

            configuredRangeFilters: {
                skuShoppable: 's_shoppable|GT+0',
                skuPromotional: 's_promotional|GT+0',
                skuSearchable: 's_searchable|GT+0',
                productTrFlag: 'p_TR_FLAG|LT+1',
                productDisplayable: 'p_displayable|GT+0',
                productShoppable: 'p_shoppable|GT+0'
            },

            defaultRangeFilters: ['skuSearchable'],

            configuredRecordFilters: {
                products: 'rec_type:product',
                content: 'rec_type:content',
                locale: 'locale:' + ( jQuery.cookie('LOCALE') || Drupal?.settings?.endeca?.locale || 'en_US' ),
                activeSkus: 'NOT(s_INVENTORY_STATUS:5)',
                discontinued: 'NOT(s_discontinued:1)',
                shoppableOrComingSoon: 'OR(s_shoppable:1,s_isComingSoon:1)'
            }

        },

        coremetricsEnabled: true,
        omnitureEnabled: true,

        contentzones: {},
        mixins: {}

    };
});
